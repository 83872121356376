.container {
  position: relative;
  width: 100%;
  height: 80vh;
}

.main_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banish_heading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 75px;
  font-weight: var(--font-weight-light);
  line-height: 1.1;
  color: var(--color-background-light);
  text-align: center;
  z-index: 1;
  white-space: nowrap;
}

.button_container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-teal-1000);
  padding: 25px var(--padding-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.footer_text {
  font-size: var( --font-size-md);
  color: var(--color-teal-100);
  font-weight: var(--font-weight-light);
  padding: 0 var(--padding-xxxl)
}

@media (max-width: 768px) {
  .banish_heading {
    font-size: 40px;
    white-space: normal;
    width: 90%;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 10px;
    padding: 15px 0px;
  }

  .footer_text {
    padding: 0 var(--padding-md);
  }
}

@media (min-width: 1600px) {
  .footer {
    padding: 25px var(--padding-xxxl);
  }
}