.section_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--padding-xl) var(--padding-md);
  background: linear-gradient(to bottom, #a0d3d1 0%, #e9f5f5 30%);
  margin-top: -10px;
}

.text_container {
  margin-top: 100px;
  text-align: center;
  margin-bottom: var(--margin-xl);
}

.heading_primary {
  color: var(--color-teal-1000);
  font-weight: var(--font-weight-light);
  line-height: 1.3;
  font-size: 70px;
}

.heading_secondary {
  color: var(--color-teal-1000);
  opacity: 0.5;
  font-weight: var(--font-weight-light);
  line-height: 1.3;
  font-size: 70px;
}

.heading_tertiary {
  color: var(--color-teal-1000);
  opacity: 0.3;
  font-weight: var(--font-weight-light);
  line-height: 1.3;
  font-size: 70px;
}

.images_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 100px;
  margin: 100px 0;
  padding: 0 var(--padding-xxxl);
}

.section_image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.yc_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 }

.yc_container img {
  width: 200px;
  height: auto;
}

.bottom_text_container {
  margin-top: 50px;
  margin-bottom: 75px;
  text-align: left;
  width: 100%;
  padding: 0 var(--padding-xxxl);
}

.streamline_header_primary {
  font-size: 75px;
  font-weight: var(--font-weight-light);
  margin-bottom: var(--margin-xs);
  line-height: 1.1;
}

.streamline_header_secondary {
  font-size: 75px;
  font-weight: var(--font-weight-light);
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .section_container {
    background: #e9f5f5;
    padding: var(--padding-xl) var(--padding-md);
    width: 100%;
  }

  .text_container {
    margin-top: 65px;
    text-align: center;
    margin-bottom: var(--margin-xl);
    width: 100%;
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  .images_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 335px;
    margin: 65px auto;
    padding: 0;
    gap: 65px;
  }

  .section_image {
    width: 100%;
    width: 335px;
  }

  .heading_primary,
  .heading_secondary,
  .heading_tertiary {
    font-size: 32px;
  }

  .streamline_header_primary,
  .streamline_header_secondary {
    font-size: 32px;
    line-height: .9;
  }

  .bottom_text_container {
    width: 100%;
    max-width: 335px;
    margin: 20px auto 30px;
    padding: 0;
  }

  .yc_container img {
    width: 100px;
    height: auto;
  }

  .images_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 65px;
    margin: 65px 0;
    padding: 0 var(--padding-xxxl);
  }
}



@media (min-width: 1600px) {
  .section_container {
    padding: var(--padding-xl) var(--padding-xxxl);
  }
}