@import 'variables.css';

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-100.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-200.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-600.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-800.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-900.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aspekta';
  src: url('../fonts/Aspekta-1000.woff2') format('woff2');
  font-weight: 1000;
  font-style: normal;
  font-display: swap;
}


/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* Base font size */
}

body {
  font-family: 'Aspekta', sans-serif;
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  color: var(--color-text-primary);
}

/* Ensure all elements inherit the font family */
* {
  font-family: inherit;
  color: inherit;
}

/* Typography scale */
h1 { font-size: 2.5rem; font-weight: 700; }
h2 { font-size: 2rem; font-weight: 600; }
h3 { font-size: 1.75rem; font-weight: 600; }
h4 { font-size: 1.5rem; font-weight: 500; }
h5 { font-size: 1.25rem; font-weight: 500; }
h6 { font-size: 1rem; font-weight: 500; }