/* StreamlineComponents.module.css */
.main_section {
  padding: var(--padding-xl) var(--padding-md);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 95%, #e9f5f5 100%);
}

.header_container {
  margin-top: 70px;
  margin-bottom: 170px;
  text-align: left;
  padding: 0 var(--padding-xxxl);
}

.header_primary {
  font-size: 75px;
  font-weight: var(--font-weight-light);
  margin-bottom: var(--margin-xs);
  line-height: 1.1;
}

.header_secondary {
  font-size: 75px;
  font-weight: var(--font-weight-light);
  line-height: 1.1;
}

.blocks_container {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-xxxl);
}

.content_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content_left {
  flex: 1;
  padding-right: var(--padding-lg);
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
}

.content_right {
  flex: 1;
  position: relative;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
}

.block_title {
  margin-bottom: var(--margin-sm);
  font-weight: var(--font-weight-light);
  font-size: 65px;
  line-height: 1.1;
  color: var(--color-teal-1000);
}

.block_subtitle {
  margin-top: 20px;
  font-weight: var(--font-weight-light);
  font-size: 25px;
  line-height: 1.28;
  color: var(--color-teal-1000);
}

.lottie_animation {
  width: 100%;
  aspect-ratio: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  padding-top: 0;
  margin-top: -35px;
}

@media (max-width: 768px) {
  .main_section {
    padding: var(--padding-xl) var(--padding-md);
  }

  .blocks_container {
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    padding: 0;
  }

  .content_block {
    flex-direction: column;
    text-align: center;
    min-height: unset;
    width: 100%;
    padding: 0;
  }

  .content_right {
    width: 100%;
    padding-top: 0;
    margin: 0 auto;
  }

  .lottie_animation {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .block_title {
    font-size: 32px;

  }

  .block_subtitle {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .header_primary,
  .header_secondary {
    font-size: 32px;
  }
}

@media (min-width: 1600px) {
  .main_section {
    padding: var(--padding-xl) var(--padding-xxxl);
  }

  .content_left {
    padding-right: 125px;
  }
}