/* SecureSection.module.css */
.secure_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E9F5F5;
  padding: var(--padding-xl) var(--padding-md);
}

.secure_title {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  font-size: 100px;
  font-weight: var(--font-weight-light);
  line-height: 1.1;
  color: var(--color-teal-1000);
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  padding: 0 var(--padding-xxxl);
}

.secure_subtitle {
  font-size: 28px;
  font-weight: var(--font-weight-light);
  line-height: 1.5;
  color: var(--color-teal-1000);
  max-width: 800px;
  margin-top: 0;
  margin-bottom: var(--margin-xxl);
}

.images_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 40px;
  padding: 0 var(--padding-xxxl);
}

.image_item {
  flex: 1;
  aspect-ratio: 3 / 4; /* Changed to portrait orientation */
  min-width: 0; /* Prevents flex items from overflowing */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .secure_container {
    padding: 0;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .secure_title {
    font-size: 32px;
    width: 335px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .secure_subtitle {
    font-size: 18px;
    width: 335px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .images_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
    width: 100%;
  }

  .image_item {
    flex: none;
    width: 100%; /* Changed from 200px */
    max-width: 335px; /* Match ImageToggle container max-width */
    margin: 0 auto;
  }

}

@media (min-width: 1600px) {
  .secure_container {
    padding: var(--padding-xl) var(--padding-xxxl);
  }
}