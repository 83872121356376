.book_demo_button {
  width: 125px;
  border: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  background-color: var(--color-accent-turquoise);
  color: var(--color-teal-1000);
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: var(--padding-xxs) var(--padding-xs);
  }
  
  .book_demo_button:hover {
    background-color: var(--color-teal-300);
  }