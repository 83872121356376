.teaser-section {
  width: 100%;
  position: relative;
}

.content-wrapper {
  position: relative;
  width: 100%;
}

.header-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  z-index: 2;
}

.lucite-logo-light {
  width: 130px;
  height: auto;
}

.lottie-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.lottie-container > div {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.buttons-container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 30px;
  z-index: 2;
}

.lottie-container svg {
  display: block;
  margin: 0;
  padding: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .lucite-logo-light {
    width: 100px;
  }

  .buttons-container {
    bottom: 20px;
    flex-direction: column;
    gap: 15px;
  }
}