/* ImageToggle.module.css */
.container {
  position: relative;
  width: 335px;
  height: 400px;
}

.image_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-color var(--transition-duration-normal) ease;
}

.flipped {
  background-color: var(--color-background-light);
}

.text_wrapper {
  position: absolute;
  top: var(--margin-xl);
  left: var(--margin-md);
  width: 75%;
}

.text {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  line-height: 1.3;
  color: white;
  word-wrap: break-word;
  display: block;
  text-align: left;
  white-space: pre-line;
}

.flipped .text {
  color: var(--color-teal-1000);
  font-weight: var(--font-weight-light);
}

.icon_button {
  position: absolute;
  bottom: var(--margin-lg);
  right: var(--margin-sm);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;  /* Set to largest icon size */
  height: 52px; /* Set to largest icon size */
}

.icon_button::before {
  content: '';
  position: absolute;
  width: 15px;  
  height: 15px; 
  background-color: white;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus_icon {
  width: 40px;
  height: 40px;
  fill: black;
  position: relative;
  z-index: 2;
}

.delete_icon {
  width: 52px;
  height: 52px;
  fill: black;
  position: relative;
  z-index: 2;
  stroke-width: 1px;
}

/* Add this to ensure the white dot stays centered */
.icon_button::before {
  width: 15px;
  height: 15px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
  }
}