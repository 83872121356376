.login_button {
  width: 125px;
  padding: var(--padding-xxs) var(--padding-xs);
  border: none;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  background-color: var(--color-gray-900);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.login_button:hover {
  opacity: 0.8;
}